import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const categoryAdd = createAsyncThunk(
    'category/categoryAdd',
    async ({name,image,parentId},{rejectWithValue,fulfillWithValue}) => {
        console.log({name,image,parentId});
        
        try {
            const formData = new FormData();
            formData.append('name',name)
            formData.append('image',image)
            formData.append('parentId',parentId)
            const {data} = await api.post('/category-add',formData, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)


export const get_category = createAsyncThunk(
    'category/get_category',
    async ({perPage,page,searchValue},{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.get(`/category-get?page=${page}&&searchValue=${searchValue}&&perPage=${perPage}`, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const get_category_select = createAsyncThunk(
    'category/get_category_select',
    async ({searchSelectBox},{rejectWithValue,fulfillWithValue}) => {
        console.log(searchSelectBox);
        
        try {
            const {data} = await api.get(`/category-get?page=0&&perPage=0&&searchValue=${searchSelectBox}`, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const delete_category = createAsyncThunk(
    'product/delete_category',
    async (payload,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.delete(`/category/${payload}`,{ data: payload , withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)


export const categoryReducer = createSlice({
    name: 'category',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        categorys: [],
        categorys_all: [],
        totalCategory: 5
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(categoryAdd.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(categoryAdd.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(categoryAdd.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.categorys = payload.categorys
            state.categorys_all = payload.categorys
            state.totalCategory = payload.totalCategory
            state.successMessage = payload.message 
        })

        .addCase(get_category.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(get_category.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(get_category.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.categorys = payload.categorys
            state.totalCategory = payload.totalCategory
        })

        .addCase(get_category_select.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(get_category_select.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(get_category_select.fulfilled, (state, { payload }) => {
            state.loader = false;
            console.log(payload)
            state.categorys_all = payload.categorys
        })

        .addCase(delete_category.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(delete_category.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(delete_category.fulfilled, (state, action ) => {
            state.loader = false;
            state.categorys = state.categorys.filter(category => category._id !== action.meta.arg)
            state.categorys_all = state.categorys_all.filter(category => category._id !== action.meta.arg)
            state.successMessage = action.payload.message 
        })
    }
})



export default categoryReducer.reducer

export const {messageClear} = categoryReducer.actions