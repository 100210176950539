import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './theme.css';
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/index'
import { Toaster } from 'react-hot-toast'
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = lazy(()=>import("./App"))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter> 
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>  
      <Provider store={store} >
        <Suspense>
          <App />
          <Toaster
            toastOptions={{
              position: 'top-right',
              style: {
                background: '#283046',
                color: '#fff'
              }
            }}
          />
        </Suspense>
      </Provider>  
    </GoogleOAuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
