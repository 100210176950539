import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const add_product = createAsyncThunk(
    'product/add_product',
    async (formData,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.post('/add-product',formData, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const update_product = createAsyncThunk(
    'product/update_product',
    async (formData,{rejectWithValue,fulfillWithValue}) => {
        console.log(formData);
        try {
            const {data} = await api.post('/update-product',formData, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)


export const get_products = createAsyncThunk(
    'product/get_products',
    async ({perPage,page,searchValue,productId},{rejectWithValue,fulfillWithValue}) => {
       
        try {
            const {data} = await api.get(`/get-products?page=${page}&&searchValue=${searchValue}&&perPage=${perPage}${productId !=='' ? `&&productId=${productId}` : ''}`, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)


export const get_product = createAsyncThunk(
    'product/get_product',
    async (productId,{rejectWithValue,fulfillWithValue}) => {
       console.log(productId);
       
        try {
            const {data} = await api.get(`/get-product/${productId}`, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)
export const delete_product = createAsyncThunk(
    'product/delete_product',
    async (payload,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.delete(`/delete-product/${payload}`,{ data: payload , withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const update_product_image = createAsyncThunk(
    'product/update_product_image',
    async ({oldImage,newImage,productId},{rejectWithValue,fulfillWithValue}) => {
        console.log({oldImage,newImage,productId});
        try {
            const formData = new FormData()
            formData.append('oldImage',oldImage)
            formData.append('newImage',newImage)
            formData.append('productId',productId)
            const {data} = await api.post('/update-product-image',formData, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)
    

export const productReducer = createSlice({
    name: 'product',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        products: [],
        product:'',
        totalProduct: 5
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(add_product.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(add_product.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(add_product.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.successMessage = payload.message 
        })

        .addCase(update_product.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(update_product.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(update_product.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.successMessage = payload.message 
            state.product = payload.product
        })

        .addCase(get_products.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(get_products.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(get_products.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.products = payload.products
            state.totalProduct = payload.totalproduct
        })

        .addCase(delete_product.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(delete_product.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(delete_product.fulfilled, (state, action ) => {
            state.loader = false;
            state.products = state.products.filter(product => product._id !== action.meta.arg)
            state.successMessage = action.payload.message 
        })

        .addCase(update_product_image.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(update_product_image.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(update_product_image.fulfilled, (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message 
            state.product = payload.product
        })

        .addCase(get_product.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.product = payload.product
        })

    }
})

export default productReducer.reducer

export const {messageClear} = productReducer.actions