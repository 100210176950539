import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";


export const get_sellers = createAsyncThunk(
    'seller/get_sellers',
    async ({perPage,page,searchValue,status},{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.get(`/get-sellers?page=${page}&&status=${status}&&searchValue=${searchValue}&&perPage=${perPage}`, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const get_seller = createAsyncThunk(
    'seller/get_seller',
    async (sellerId,{rejectWithValue,fulfillWithValue}) => {
       console.log(sellerId);
       
        try {
            const {data} = await api.get(`/get-seller/${sellerId}`, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)


export const update_seller = createAsyncThunk(
    'seller/update_seller',
    async (formData,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.post('/update-seller',formData, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const sellerReducer = createSlice({
    name: 'seller',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        sellers: [],
        seller: '',
        totalSeller: 5
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(get_sellers.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(get_sellers.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(get_sellers.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.sellers = payload.sellers
            state.totalSeller = payload.totalSeller
        })

        .addCase(get_seller.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.seller = payload.seller
        })

        .addCase(update_seller.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(update_seller.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(update_seller.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.successMessage = payload.message 
            state.seller = payload.seller
        })
    }
})

export default sellerReducer.reducer

export const {messageClear} = sellerReducer.actions